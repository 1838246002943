/**
 * Data Source types
 */
export enum DataSourceType {
  SOFTWARE = 'redis-enterprise-software-datasource',
}

/**
 * New Data Source names
 */
export enum DataSourceName {
  SOFTWARE = 'Redis Enterprise Software',
}

/**
 * Application root page
 */
export const ApplicationRoot = '/a/redis-explorer-app';
